.panel {
  background-color: #444;
  max-width: 20%;
  margin: 20px;
  padding: 20px;
  text-align: center;
}

.panel h2 {
  margin-top: 0px;
}

.view {
  background-color: #444;
  max-width: 50%;
  overflow: auto;
  padding: 20px;
  margin: 20px;
}

.cell {
  width: 30px;
  height: 30px;
  padding: 0px;
  margin: 0px;
}

.bordered {
  border: 2px solid black;
}

.title {
  font-size: 2em;
}

.preview {
  display: block;
  width: 200px;
  margin: 20px;
}
