body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #333;
  color: white;
}

nav {
  display: flex;
  justify-content: space-between;
}

nav * {
  padding: 10px;
}

nav a {
  color: white;
  font-size: 1.25em;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

.button {
  display: inline-block;
  padding: 10px;
  margin: 0px 5px 0px 5px;
  background-color: #F08080;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  border: none;
  font-size: 1em;
}

.button.bordered {
  border: 1px solid white;
}

.button:hover {
  filter: brightness(85%);
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

td {
  text-align: left;
}

.error {
  color: red;
  margin: 0px;
  margin-bottom: 5px;
}

.centered {
  text-align: center;
}

.border-right {
  border-right: 1px solid white;
  margin-right: 20px;
  padding-right: 20px;
}

.highlight {
  color: #F08080;
  cursor: pointer;
}

.icon {
  width: 20px;
}

.flex-sides {
  margin: 10px;
  display: flex;
  justify-content: space-between;
}
